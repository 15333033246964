@import '../../../../styles/colors';
@import '../../../../styles/variables';

@mixin form-field-radio-core() {
  .form-field-radio-label {
    display: block;
    position: relative;
  }

  .form-field-radio-input {
    height: 28px;
    width: 28px;
    border-radius: 15px;
    display: inline-block;
    border: 1px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .form-field-radio {
    display: block;

    @extend %form-field-rounded-border;

    .form-field-radio-label {
      padding: 18px 0 19px $form-field-input-padding-left;
    }
  }
}

@mixin form-field-radio-theme($theme) {
  $form-field-radio-label-color: map-get($theme, tertiary-color-normal);
  $form-field-radio-label-valid-color: map-get($theme, form-field-valid-field-color);

  .form-field-radio {
    &.form-field-radio--checked {
      border: 1px solid $form-field-radio-label-valid-color;
    }
  }

  .form-field-radio-label {
    @include form-field-primary-text($form-field-radio-label-color);
  }

  .form-field-round-radio {
    .form-field-radio-label::before {
      content: '';
      flex: none;
      height: 28px;
      width: 28px;
      border-radius: 15px;
      display: inline-block;
      border: 1px solid $form-field-input-initial-color;
      vertical-align: middle;
      margin-right: 10px;
      cursor: pointer;
    }

    .form-field-radio-label::after {
      content: '';
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: 6px;
      top: 6px;
      border-width: 9px;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
    }

    &.form-field-radio--checked {
      .form-field-radio-label::after {
        border-color: #546aeb;
      }
    }
  }
}
