//
// Mixin that creates a css arrow.
//
// @param {String} $direction - top|bottom|left|right
// @param {CssMeasure} $height - arrow's height (diagonal)
// @param {CssMeasure} $borderWith - arrow's border width
// @content - overrides
//
// @example
//   .a-bottom-arrow {
//     @include box-arrow(bottom, 20px);
//   }
//

@mixin box-arrow($direction: right, $height: 10px, $border-width: 1px) {
  $diagonal: calc(#{$height} / 1.4142135623730951); // magic number: Math.sqrt(2)

  display: inline-block;
  height: $diagonal;
  width: $diagonal;
  box-sizing: border-box;
  border-width: 0 $border-width $border-width 0;
  border-style: solid;
  padding: 0;

  @if $direction == 'top' {
    transform: rotate(-135deg);
  } @else if $direction == 'bottom' {
    transform: rotate(45deg);
  } @else if $direction == 'left' {
    transform: rotate(135deg);
  } @else {
    transform: rotate(-45deg);
  }

  @content;
}

//
// Mixin that renders a line throught an entire block
//
// @param {CssColor} $color? - line color
// @param {Number} $opacity? -
// @param {CssMeasure} $height? - line height
// @param {CssMeasure} $spacing? - spaces's width between title and lines
// @content - overrides
//
// @example
//   .a-blue-title-center-between-two-lines {
//     @include line-through-block(blue);
//   }
@mixin line-through-block($color: inherit, $opacity: 1, $height: 1px, $spacing: 10px) {
  & {
    display: flex;
    flex-basis: 100%;
    align-items: center;

    // hide overflow created by negative margin
    overflow-x: hidden;

    @content;
  }

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    background: $color;
    height: $height;
    font-size: 0;
    line-height: 0;
    margin: 0;
    opacity: $opacity;
  }

  &:before {
    margin-right: $spacing;
    margin-left: -calc(#{$spacing} / 2);
  }

  &:after {
    margin-left: $spacing;
    margin-right: -calc(#{$spacing} / 2);
  }
}

///
/// Slice & Page Structure
///
@mixin container {
  display: block;
  box-sizing: border-box;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @content;
}

@mixin content {
  display: block;
  box-sizing: border-box;
  max-width: 1100px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @content;
}

@mixin content-medium {
  @include content {
    max-width: 702px;
  }
}

///
/// Device Target
///
@mixin mobile {
  @media screen and (max-width: calc(#{$break} - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $break) {
    @content;
  }
}

@mixin mobile-only {
  @include desktop {
    display: none;
  }
}

@mixin desktop-only {
  @include mobile {
    display: none;
  }
}

@mixin hidpi {
  @media only screen and (min-resolution: 144dpi) {
    @content;
  }
}

///
/// Grid
///
@mixin grid-container($gutter-size: 12px) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;

  @if $gutter-size > 0 {
    margin-right: calc(-#{$gutter-size} / 2);
    margin-left: calc(-#{$gutter-size} / 2);
  }
}

@mixin grid-item($item-per-line, $item-vertical-space: 0, $gutter-size: 12px) {
  @if $item-per-line > 1 {
    display: flex;
    align-items: center;
    width: calc(100% / #{$item-per-line});
    max-width: calc(100% / #{$item-per-line});
    box-sizing: border-box;
  } @else {
    width: 100%;
  }

  @if $gutter-size > 0 {
    padding-right: calc(#{$gutter-size} / 2);
    padding-left: calc(#{$gutter-size} / 2);
  }

  &:nth-child(#{$item-per-line}n) {
    margin-right: 0;
  }

  @if $item-vertical-space > 0 {
    &:nth-child(n + #{$item-per-line + 1}) {
      margin-top: $item-vertical-space;
    }
  }
}

///
/// Articles
///
@mixin article-title($label-color-normal, $label-color-hover: $label-color-normal) {
  .article-title {
    color: $label-color-normal;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 0;
    display: block;
    padding-bottom: 2px;

    @include desktop {
      line-height: 24px;
      margin-bottom: 7px;
      display: inherit;
    }
  }

  a:hover {
    .article-title {
      color: $label-color-hover;

      @include desktop {
        border-bottom: 1px solid $label-color-hover;
      }
    }
  }
}

///
/// CTA
///
@mixin link(
  $label-color-normal,
  $label-color-hover: $label-color-normal,
  $underline-normal: true,
  $underline-hover: $underline-normal
) {
  .label {
    color: $label-color-normal;
    line-height: 19px;

    @include desktop {
      line-height: 22px;
    }

    @if $underline-normal {
      border-bottom: 1px solid;
    }
  }

  &:hover {
    .label {
      color: $label-color-hover;

      @if $underline-hover {
        border-bottom: 1px solid;
      }
    }
  }
}

/**
 * The template must be similar as:
 * <button><span class="label">label</span></button>
 * or
 * <a><span class="label">label</span></a>
 */
@mixin button-link(
  $background-color-normal,
  $background-color-hover: $background-color-normal,
  $label-color-normal: #ffffff,
  $label-color-hover: $label-color-normal
) {
  background-color: $background-color-normal;
  color: $label-color-normal;

  @if $background-color-hover {
    &:hover {
      opacity: 0.85;
    }
  } @else {
    &:hover {
      background-color: $background-color-hover;
      color: $label-color-hover;
    }
  }

  &,
  &:hover {
    .label {
      color: $label-color-normal;
    }
  }
}

@mixin outline-button-link(
  $border-color,
  $label-color-normal,
  $label-color-hover: $label-color-normal,
  $box-shadow-color-normal: null,
  $box-shadow-color-hover: null,
  $background-color: #ffffff
) {
  background-color: $background-color;
  color: $label-color-normal;
  border: 1px solid $border-color;
  font-weight: 600;
  box-shadow: 5px 3px 12px 0 $box-shadow-color-normal;

  &:hover {
    color: $label-color-hover;
    box-shadow: 3px 3px 12px 0 $box-shadow-color-normal;
  }

  &:hover {
    box-shadow: 3px 3px 12px 0 $box-shadow-color-hover;
  }
}

@mixin outline-button-icon(
  $border-color,
  $icon-url-normal,
  $icon-url-hover: null,
  $box-shadow-color-normal: null,
  $box-shadow-color-hover: null
) {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white $icon-url-normal no-repeat center;
  border: 1px solid $border-color;
  background-size: 16px 10px;
  cursor: pointer;
  box-shadow: 5px 3px 12px 0 $box-shadow-color-normal;

  &:hover {
    background: white $icon-url-hover no-repeat center;
    box-shadow: 3px 3px 12px 0 $box-shadow-color-normal;
  }

  &:hover {
    box-shadow: 3px 3px 12px 0 $box-shadow-color-hover;
  }
}

@mixin arrow-icon(
  $icon-url-normal,
  $icon-url-hover: $icon-url-normal,
  $animated: true,
  $color-arrow: $icon-url-normal
) {
  .label {
    .label-icon {
      width: 16px;
      height: 10px;
      margin-left: 6px;
      display: inline-block;

      -webkit-mask: $icon-url-normal no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask: $icon-url-normal no-repeat 50% 50%;
      mask-size: cover;

      @include desktop {
        margin-left: 12px;
      }
    }
  }

  @if $animated {
    .label .label-icon {
      margin-right: 8px;
      transition: margin 0.8s ease;

      @include desktop {
        margin-right: 8px;
      }
    }

    &:hover {
      .label .label-icon {
        margin-left: 14px;
        margin-right: 0;

        @include desktop {
          margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }
}

@mixin inverted-arrow-icon($icon-url-normal, $icon-url-hover: $icon-url-normal, $animated: true) {
  .label {
    &::before {
      background: $icon-url-normal;
      content: '';
      width: 16px;
      height: 10px;
      margin-left: 6px;
      display: inline-block;

      @include desktop {
        margin-left: 12px;
      }
    }
  }

  &:hover {
    .label::before {
      background: $icon-url-hover;
    }
  }

  @if $animated {
    .label::before {
      margin-left: 8px;
      transition: margin 0.8s ease;

      @include desktop {
        margin-left: 8px;
      }
    }

    &:hover {
      .label::before {
        margin-left: 0;
        margin-right: 14px;

        @include desktop {
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }
  }
}

@mixin add-file-icon() {
  .label {
    &::before {
      height: 29px;
      width: 15px;
      color: #727efd;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 29px;
      text-align: center;
      margin-left: 6px;
      display: inline-block;
      vertical-align: -15%;
      content: '+';

      @include desktop {
        margin-left: 12px;
      }
    }
  }
}

///
/// Forms
///
@mixin form-field-primary-text($color) {
  color: $color;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
