@import '../../../../styles/core';

$checkbox-checked-background-image: url('/assets/images/checkbox-mark-white.svg');
$checkbox-disabled-background-color: #eef0f8;
$checkbox-unchecked-background-color: #fff;

:host {
  cursor: pointer;
  display: inline-block;
  transition: background 0.2s;

  &,
  .checkmark {
    outline: none;
  }

  &.checked {
    .checkmark {
      background-image: $checkbox-checked-background-image;
    }
  }

  &.disabled {
    cursor: default;

    .checkmark {
      background-color: $checkbox-disabled-background-color;
    }

    &.checked .checkmark {
      opacity: 0.5;
    }
  }
}

label {
  color: inherit;
  cursor: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: inherit;
}

.checkmark {
  background: $checkbox-unchecked-background-color;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  box-shadow: 0 3px 8px 0 rgba(26, 57, 198, 0.08);
  cursor: inherit;
  display: inline-block;
  height: 24px;
  margin-right: 10px;
  transition: inherit;
  vertical-align: middle;
  width: 24px;
}

@include mobile {
  .checkmark {
    height: 20px;
    width: 20px;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin form-field-checkbox-theme($theme) {
  $checkbox-checked-background-color: map-get($theme, button-primary-color-normal);

  form-field-checkbox {
    &.checked {
      .checkmark {
        background-color: $checkbox-checked-background-color;
        border-color: $checkbox-checked-background-color;
      }
    }
  }
}
