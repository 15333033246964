@import '../../../../styles/colors';
@import '../../../../styles/variables';

@mixin form-field-control-core() {
  .form-field-control {
    position: relative;
    display: block;
    padding-bottom: $form-field-control-bottom-space;
    line-height: 0;

    + .form-field-control {
      margin-top: 7px;
    }

    > label {
      display: block;
      font-size: 14px;
      line-height: 22px;

      + .form-field-input,
      + .form-field-radio-group,
      + .bs-timepicker-field,
      + .form-field-radio-group,
      + .mat-input-element,
      > .form-field-input,
      > .ng-select {
        margin-top: 8px;
      }
    }

    &.form-field-control--invalid {
      label {
        color: $form-field-input-error-color !important;
      }

      .form-field-input {
        color: $form-field-input-error-color;
        border-color: $form-field-input-error-color;
      }

      .form-field-radio {
        border-color: $form-field-input-error-color;
      }

      .bs-timepicker-field {
        color: $form-field-input-error-color;
        border-color: $form-field-input-error-color;
      }

      .mat-input-element {
        color: $form-field-input-error-color;
        border-color: $form-field-input-error-color;
      }
    }
  }
}

@mixin form-field-control-theme($theme) {
  $form-field-control-label-color: map-get($theme, tertiary-color-normal);
  $form-field-control-valid-color: map-get($theme, form-field-valid-field-color);

  .form-field-control {
    > label {
      color: $form-field-control-label-color;
    }

    &.form-field-control--valid {
      .form-field-input {
        border-color: $form-field-control-valid-color;
      }
    }
  }
}
