//
// normalize.css
// https://github.com/necolas/normalize.css
//
// Preserves useful defaults, unlike many CSS resets.
// Normalizes styles for a wide range of elements.
// Corrects bugs and common browser inconsistencies.
// Improves usability with subtle modifications.
// Explains what code does using detailed comments.
//
@import './styles/normalize';

//
// Core styles
//
@import './styles/_core';

//
// Global styles
//
@import './styles/icons';
@import './styles/layout';
@import './styles/typography';
@import './styles/loader';
@import './styles/animations';
@import './styles/material';
@import './styles/forms';

//
// Additional styles
//
// Generated by webfontsGenerator
@import './webfont/lcl-icons.css';

//
// Shared styles
//
@import './app/ui/_ui';

@include ui-core;

@include apply-themes($lcl-themes) {
  @include ui-theme($theme-context-map);
}

//
// Here Map
//
@import './styles/map';

.a11y-focus:focus-visible {
  &:not(:disabled):not(.disabled) {
    border: 3px solid black !important;
    outline: none;
  }

  &:not(:disabled):not(.disabled).focus-light-outline {
    border: 3px solid black !important;
    outline: none;
  }

  &:not(:focus) {
    outline: none !important;
  }
}
