@import 'mixins';
@import 'colors';
@import 'variables';
@import 'themes';
@import '~@ng-select/ng-select/themes/default.theme.css';

//
// NgSelect styles
//
.ng-select.ng-select-single {
  .ng-select-container {
    height: 60px;

    @extend %form-field-rounded-border;

    .ng-value-container {
      padding-left: $form-field-input-padding-left;
    }
  }

  .ng-arrow-wrapper {
    padding-right: 30px;

    .ng-arrow {
      display: none;
    }

    &:before {
      content: '';
      @include box-arrow(bottom, 13px, 2px);

      transition: transform 0.2s ease-in-out;
    }
  }

  &.ng-select-opened .ng-arrow-wrapper:before {
    transform: rotate(225deg);
  }

  &.ng-select-focused .ng-select-container,
  &.ng-touched .ng-select-container {
    box-shadow: initial;
  }

  .ng-dropdown-panel {
    .ng-option {
      padding-left: $form-field-input-padding-left;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: #f6f6f6;
      }

      &.ng-option-disabled {
        color: grey;
      }

      &.ng-option-marked {
        background-color: transparent;
      }
    }
  }
}

@mixin forms-theme($theme) {
  $ng-select-color: map-get($theme, tertiary-color-normal);
  $ng-select-valid-color: map-get($theme, form-field-valid-field-color);
  $ng-select-option-selected: map-get($theme, tertiary-color-normal);
  $ng-select-option-marked: map-get($theme, select-option-marked);

  .ng-select.ng-select-single {
    .ng-select-container {
      @include form-field-primary-text($ng-select-color);
    }

    .ng-arrow-wrapper {
      &:before {
        color: $ng-select-valid-color;
      }
    }

    &.ng-select-focused .ng-select-container,
    &.ng-touched .ng-select-container {
      border-color: $ng-select-valid-color;
    }

    .ng-dropdown-panel {
      .ng-option {
        @include form-field-primary-text($ng-select-color);

        &.ng-option-selected {
          color: $ng-select-option-selected;
        }

        &.ng-option-disabled {
          color: grey;
        }

        &.ng-option-marked {
          color: $ng-select-option-marked;
        }
      }
    }
  }
}

.recaptcha {
  display: block;
  padding-top: 18px;

  re-captcha {
    display: block;

    @media screen and (max-width: $xs-break) {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }

  .form-field-input {
    border: none;
    padding: 0;
  }

  .form-field-error {
    @include mobile {
      line-height: 18px;
    }
  }
}

@include apply-themes($lcl-themes) {
  @include forms-theme($theme-context-map);
}
