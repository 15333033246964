@mixin ui-slideshow-block-core() {
  ui-slideshow-block {
    display: block;

    .nav-container {
      display: block;
      z-index: 1;
    }

    .nav-ink {
      position: absolute;
    }

    .swipe {
      overflow: hidden;
      visibility: hidden;
      position: relative;
    }

    .swipe-wrap {
      overflow: hidden;
      position: relative;
    }

    .swipe-wrap *[uislideshowslide] {
      float: left;
      width: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}
