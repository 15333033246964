@import './core';

.ng-bar-placeholder {
  height: 4px !important;
  background-color: #f3f3f3 !important;
}

@mixin ng-bar-theme($theme) {
  $ng-bar-background-color: map-get($theme, secondary-color-normal) !important;

  .ng-bar {
    background-color: $ng-bar-background-color;
  }
}

@include apply-themes($lcl-themes) {
  @include ng-bar-theme($theme-context-map);
}
