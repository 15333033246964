//
// Mixin that renders `<ui-expansion-panel>` core styles.
//
@mixin ui-expansion-panel-core {
  $expansion-panel-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;

  .ui-expansion-panel {
    box-sizing: content-box;
    display: block;
    margin: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 2px #1b2b7614;
    transition: margin 225ms $expansion-panel-fast-out-slow-in-timing-function;

    .ui-expansion-panel-content {
      overflow: hidden;

      &.ui-expanded {
        overflow: visible;
      }
    }

    .ui-expansion-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      & * {
        margin: 0;
        padding: 0;
      }

      .ui-expansion-header-title {
        flex-grow: 1;
      }

      &:focus,
      &:hover {
        outline: none;
      }

      &[aria-expanded='true']:focus,
      &[aria-expanded='true'] {
        background: inherit;
      }

      .ui-expansion-header-indicator {
        &-arrow {
          @include box-arrow(bottom, 17px, 2px);
          color: #1f368b;
        }
      }
    }
  }
}

//
// Mixin that renders `<ui-expansion-panel>` theme styles.
//
@mixin ui-expansion-panel-theme($theme) {
  $expansion-panel-title-color: map-get($theme, tertiary-color-normal);

  .ui-expansion-panel {
    .ui-expansion-header {
      line-height: $line-height-body;
      padding-right: 26px;

      @include desktop {
        line-height: 32px;
      }

      .ui-expansion-header-title {
        font-size: 16px;
        font-weight: 600;
        margin: 20px 5px 20px 0;
      }

      color: #e7e7e7;

      .ui-expansion-header-title,
      .ui-expansion-header-title * {
        color: $expansion-panel-title-color;
        margin-left: 4%;
      }
    }

    .ui-expansion-panel-body {
      color: $text-primary-color;
      font-size: $font-size-body;
      line-height: $line-height-body;
      padding: 0 24px 12px 32px;
      text-align: justify;

      & img {
        max-width: 100%;
        height: auto;
      }

      @include desktop {
        font-size: $font-size-body-desktop;
        line-height: $line-height-body-desktop;
        padding: 10px 100px 24px 50px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.ui-expanded {
      .ui-expansion-header-indicator-arrow {
        color: $expansion-panel-title-color;
      }
    }
  }
}
