@font-face {
  font-family: 'lcl-icons';
  src: url('lcl-icons.eot?d07008c17d5a4067dae8c62e4691cc42?#iefix') format('embedded-opentype'),
    url('lcl-icons.woff2?d07008c17d5a4067dae8c62e4691cc42') format('woff2'),
    url('lcl-icons.woff?d07008c17d5a4067dae8c62e4691cc42') format('woff');
}

.ic {
  line-height: 1;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ic:before {
  font-family: lcl-icons;
  font-style: normal;
  font-weight: normal;
  vertical-align: top;
}

.ic-arrow:before {
  content: '\f101';
}
.ic-bt-close:before {
  content: '\f102';
}
.ic-chevron:before {
  content: '\f103';
}
.ic-download:before {
  content: '\f104';
}
.ic-facebook:before {
  content: '\f105';
}
.ic-linkedin:before {
  content: '\f106';
}
.ic-mail:before {
  content: '\f107';
}
.ic-man:before {
  content: '\f108';
}
.ic-print:before {
  content: '\f109';
}
.ic-tab-check:before {
  content: '\f10a';
}
.ic-tweet:before {
  content: '\f10b';
}
.ic-woman:before {
  content: '\f10c';
}
