@import '../../../../styles/_core';

//
// Mixin that renders `<ui-push-block>` core styles.
//
@mixin ui-push-block-core() {
  $block-link-per-line-mobile: 1;
  $block-link-per-line-desktop: 3;
  $block-link-vertical-space-mobile: 21px;
  $block-link-vertical-space-desktop: 25px;

  .ui-push-block {
    display: block;

    .ui-push-block-header {
      display: block;
      padding: 40px 33.33% 130px 32px;
      margin-bottom: -120px;
      background-repeat: no-repeat !important;
      background-position: center top !important;
      background-size: cover !important;

      &[aria-bg-gradient='true'] {
        background-size: 100% 100%;
      }

      h2,
      p {
        color: #ffffff !important;
        letter-spacing: 2px;

        @include desktop {
          letter-spacing: 3px;
        }
      }

      h2 {
        font-size: 12px !important;
        font-weight: $font-weight-body !important;
        text-transform: uppercase;
        line-height: 22px;

        @include desktop {
          font-size: 16px !important;
          line-height: 32px;
        }
      }

      p {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 26px;
        line-height: 32px;

        @include desktop {
          font-size: 42px;
          margin-bottom: 50px;
          line-height: 57px;
        }
      }

      @include desktop {
        padding-top: 90px;
        padding-left: 120px;
      }
    }

    .ui-push-block-content {
      margin-left: 16px;
      margin-right: 16px;
      padding: 20px;

      @include desktop {
        margin-left: 60px;
        margin-right: 60px;
        padding: 60px;
      }

      .ui-push-block-group {
        display: block;
        margin-bottom: 30px;

        @include desktop {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include desktop {
        display: flex;

        .ui-push-block-group {
          .ui-push-block-group-content {
            display: flex;

            .ui-push-block-item {
              flex: 1;
              margin-right: 30px;
            }
          }
        }
      }

      .ui-push-block-item {
        display: block;
        width: 100%;
      }

      .ui-push-block-links {
        @include grid-container;

        > * {
          @include mobile {
            @include grid-item($block-link-per-line-mobile, $block-link-vertical-space-mobile);
            min-height: 22px;
          }

          @include desktop {
            @include grid-item($block-link-per-line-desktop, $block-link-vertical-space-desktop);
            min-height: 28px;
          }
        }
      }
    }
  }
}

//
// Mixin that renders `<ui-push-block>` theme styles.
//
@mixin ui-push-block-theme($theme) {
  $push-block-item-label-font-weight: map-get($theme, push-block-item-label-font-weight);
  $push-block-group-title-color: map-get($theme, push-block-group-title-color);
  $push-block-header-background-color: map-get($theme, push-block-header-background-color);

  .ui-push-block {
    .ui-push-block-header {
      background: $push-block-header-background-color;
    }

    .ui-push-block-content {
      background: #ffffff;
      box-shadow: 0 11px 21px 0 rgba(27, 43, 118, 0.05);

      .ui-push-block-group {
        .title {
          font-size: 14px;
          font-weight: $font-weight-body;
          color: $push-block-group-title-color;
          margin-bottom: 16px;

          @include mobile {
            &:first-child {
              margin-top: 10px;
            }
          }
        }

        .ui-push-block-item {
          @include mobile {
            + .ui-push-block-item {
              margin-top: 15px;
            }
          }

          ::ng-deep .label {
            font-weight: $push-block-item-label-font-weight;
          }

          p {
            margin-top: 10px;
            line-height: 23px;

            @include desktop {
              margin-top: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
