span[class^='ic-'],
span[class*=' ic-'] {
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
}

.ic-article {
  background: url('/assets/images/ic_article.svg');
  height: 13px;
  width: 11px;
}

.ic-eye {
  background: url('/assets/images/ic_eye.svg');
  width: 16px;
  height: 10px;
}

.ic-infographics {
  background: url('/assets/images/ic_infographic.svg');
  height: 13px;
  width: 11px;
}

.ic-mail {
  background: url('/assets/images/ic_mail.svg');
  height: 12px;
  width: 16px;
}

.ic-phone {
  background: url('/assets/images/ic_phone.svg');
  height: 22px;
  width: 13px;
}

.ic-press {
  background: url('/assets/images/ic_press.svg');
  height: 13px;
  width: 11px;
}

.ic-video {
  background: url('/assets/images/ic_video.svg');
  height: 12px;
  width: 12px;
}

.ic-pdf {
  background: url('/assets/images/ic_pdf.svg');
  width: 18px;
  height: 22px;
}

.ic-check {
  background: url('/assets/images/ic_check.svg');
  width: 11px;
  height: 8px;
}

.ic-arrow {
  background: url('/assets/images/arrow_links_blue.svg');
  width: 16px;
  height: 10px;
}
