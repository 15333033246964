@import '../../../../styles/colors';
@import '../../../../styles/variables';

@mixin form-field-timepicker-core() {
  .bs-timepicker-field {
    width: 70px !important;
    box-sizing: border-box;
    padding: 18px 20px 19px $form-field-input-padding-left !important;
    background-clip: padding-box; // https://stackoverflow.com/questions/23211656/remove-ios-input-shadow/23211871
    text-align: center;

    @extend %form-field-rounded-border;

    &:focus {
      outline: none;
    }
  }
}

@mixin form-field-timepicker-theme($theme) {
  $form-field-input-color: map-get($theme, tertiary-color-normal);
  $form-field-input-valid-field-color: map-get($theme, form-field-valid-field-color);
  $form-field-input-placeholder-color: map-get($theme, form-field-placeholder-color);

  .bs-timepicker-field {
    @include form-field-primary-text($form-field-input-color);

    &:focus {
      border: 1px solid $form-field-input-valid-field-color;
    }

    &::placeholder {
      color: $form-field-input-placeholder-color;
    }
  }
}
