@import '../../../../styles/_core';

//
// Mixin that renders `<ui-contacts-block>` core styles.
//
@mixin ui-contacts-block-core() {
  .ui-contacts-block {
    display: block;

    .ui-contacts-block-title {
      display: block;
      margin-bottom: 30px;
    }
  }

  .ui-contacts-item {
    display: block;
    line-height: $line-height-body;
    margin-bottom: 26px;

    @include desktop {
      line-height: $line-height-body-desktop;
      margin-bottom: 50px;
    }

    .ui-contacts-item-title {
      display: block;
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        padding: 0;
      }
    }
  }
}

//
// Mixin that renders `<ui-contacts-block>` theme styles.
//
@mixin ui-contacts-block-theme($theme) {
  .ui-contacts-block {
    .ui-contacts-block-title {
      @include mobile {
        @include line-through-block($section-title-line-color);
      }
    }

    &:not(.with-bg) &-title {
      @include line-through-block($section-title-line-color);
    }

    &.with-bg {
      background-repeat: no-repeat;
      background-position: left top;
      margin-bottom: 15px;

      // no background on mobile
      background-size: 0 0;

      @include desktop {
        min-height: 530px;
        box-sizing: border-box;
        background-size: 430px 530px;
        margin-bottom: 26px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 550px;
        max-width: 887px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    margin-bottom: 48px;

    @include desktop {
      margin-bottom: 100px;
    }
  }

  .ui-contacts-item {
    line-height: $line-height-body;

    @include desktop {
      line-height: $line-height-body-desktop;
    }

    ul li {
      :first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 3px;
      margin-left: 0;

      > span {
        display: inline-block;
        width: 25px;
      }

      &::before {
        content: '';
      }
    }
  }
}
