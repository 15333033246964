@import '../../../../styles/colors';
@import '../../../../styles/variables';

@mixin form-field-error-core() {
  .form-field-error {
    font-size: 12px;
    line-height: 28px;
    position: absolute;
    bottom: 0;
    color: $form-field-input-error-color;
    left: 0;

    &.form-field-error--hidden {
      display: none;
    }
  }
}
