@import './_mixins';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

dd {
  margin: 0;
}

.container {
  @include container();
}

.content {
  @include content();
}

.content-medium {
  @include content-medium();
}

.desktop-only {
  @include desktop-only();
}

.mobile-only {
  @include mobile-only();
}

@media print {
  app-footer,
  app-desktop-header > div.header {
    display: none !important;
  }

  app-header > div.header > div.header-desktop {
    display: block !important;
  }

  span[class^='ic-'] {
    -webkit-print-color-adjust: exact;
  }
}

body.iframe {
  div#footer_tc_privacy {
    display: none !important;
  }
}
