//
// Mixin that renders `<ui-faq-page>` core styles.
//
@mixin ui-faq-core() {
  .ui-faq {
    // .ui-faq-header
    &-header {
      // align background top right to push/hide extra-size on the left
      background-position: top right;

      // no background on mobile
      background-size: 0 0;
      @include desktop {
        background-size: cover;
      }

      &-content {
        padding-top: 48px;
        padding-bottom: 48px;
        @include desktop {
          padding-top: 100px;
          padding-bottom: 100px;
        }
      }

      // when header has background
      &.ui-header-background {
        box-sizing: border-box;

        @include desktop {
          // add header bottom margin
          margin-bottom: 100px;
        }
      }
    }

    &-section {
      display: block;
      margin-bottom: 48px;
      @include desktop {
        margin-bottom: 100px;
      }

      & h2,
      & *[role='section-h1'],
      & *[role='section-h2'] {
        margin-bottom: 26px;
        @include desktop {
          margin-bottom: 50px;
        }
      }
    }
  }
}

//
// Mixin that renders `<ui-faq-page>` theme styles.
//
@mixin ui-faq-theme($theme) {
  .ui-faq {
    // .ui-faq-header
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &-content {
        // center header content title and description
        & .ui-faq-title,
        & .ui-faq-description {
          box-sizing: border-box;
          margin-left: auto;
          margin-right: auto;
        }

        & .ui-faq-title {
          display: block;
          max-width: 380px;
        }

        & .ui-faq-description {
          display: block;
          max-width: 460px;

          &,
          & p:last-child {
            margin-bottom: 0;
          }
        }

        &:not(.ui-header-background) {
          text-align: center;
        }
      }
      // when header is dark
      &.ui-header-dark {
        @include desktop {
          & .ui-faq-title,
          & .ui-faq-title *,
          & .ui-faq-description,
          & .ui-faq-description * {
            color: #fff;
          }
        }
      }

      // when header has background
      &.ui-header-background {
        @include desktop {
          height: 490px;
          overflow: hidden;

          & .ui-faq-header-content {
            text-align: left;
          }

          & .ui-faq-title,
          & .ui-faq-description {
            margin-left: 0;
          }
        }
      }
    }

    &-section {
      & h2,
      & *[role='section-h1'],
      & *[role='section-h2'] {
        text-align: center;
      }
    }
  }
}
