@import './core';
@import '~typeface-montserrat/index.css';

html,
body {
  font-family: 'Montserrat', sans-serif;
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $line-height-body;
  color: $text-primary-color;

  @include desktop {
    font-size: $font-size-body-desktop;
    line-height: $line-height-body-desktop;
  }
}

h1:not([role]),
*[role='h1'],
h2:not([role]),
*[role='h2'],
h3:not([role]),
*[role='h3'] {
  &.hr {
    @include line-through-block($section-title-line-color);
  }
}

h1:not([role]),
*[role='h1'] {
  font-size: $font-size-xlarge;
  line-height: 32px;
  margin: 0 0 15px 0;

  @include desktop {
    font-size: $font-size-xlarge-desktop;
    line-height: 57px;
    margin-bottom: 26px;
  }
}

h2:not([role]),
*[role='h2'] {
  font-size: $font-size-large;
  line-height: $line-height-body;
  margin: 0 0 12px 0;

  @include desktop {
    font-size: $font-size-large-desktop;
    line-height: 32px;
    margin-bottom: 15px;
  }

  + h3 {
    margin-top: 20px;
  }
}

h3:not([role]),
*[role='h3'] {
  margin: 0;
  font-size: $font-size-body;
  line-height: 20px;

  @include desktop {
    font-size: $font-size-body-desktop;
    line-height: $line-height-body-desktop;
  }
}

*[role='section-h1'],
*[role='section-h2'] {
  color: $section-title-color;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;

  &.hr {
    @include line-through-block($section-title-line-color);
  }
}

*[role='section-h1'] {
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $line-height-body;
  margin-bottom: 10px;

  @include desktop {
    font-size: $font-size-body-desktop;
    line-height: 32px;
    margin-bottom: 14px;
  }
}

*[role='section-h2'] {
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $line-height-body;
  margin-bottom: 8px;

  @include desktop {
    font-size: $font-size-body-desktop;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

p {
  margin: 0 0 15px 0;

  &:empty {
    min-height: 1px;
  }

  + h3 {
    margin-top: 30px;
  }

  + ol,
  ul {
    margin-top: 10px;
  }

  + img {
    margin-top: 20px;

    @include desktop {
      margin-top: 30px;
    }
  }

  @include desktop {
    margin-bottom: 26px;
  }
}

img + p {
  margin-top: 20px;

  @include desktop {
    margin-top: 30px;
  }
}

a,
.link {
  text-decoration: none;
  cursor: pointer;
}

a:not([href]) {
  cursor: default;
}

strong,
b {
  font-weight: 500;
}

em {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
}

ol,
ul {
  margin: 0 0 0 20px;
  padding: 0;

  li:first-child {
    margin-top: 15px;

    @include desktop {
      margin-top: 10px;
    }
  }

  li:last-child {
    margin-bottom: 15px;

    @include desktop {
      margin-bottom: 26px;
    }
  }

  @include desktop {
    margin-left: 30px;
  }

  + h3 {
    margin-top: 30px;
  }
}

ol {
  margin-left: 35px;

  @include desktop {
    margin-left: 45px;
  }
}

a[href^='tel:'] {
  white-space: nowrap;
}

.btn--see-more {
  line-height: 28px;
  font-weight: 500;
}

@mixin base-theme($theme) {
  $base-primary-color: map-get($theme, tertiary-color-normal);
  $base-H1-font-weight: map-get($theme, H1-font-weight);
  $base-H2-font-weight: map-get($theme, H2-font-weight);
  $base-H3-font-weight: map-get($theme, H3-font-weight);
  $base-hypertext-link-color-normal: map_get($theme, secondary-color-normal);
  $base-hypertext-link-color-hover: map_get($theme, secondary-color-hover);
  $see-more-color-normal: map-get($theme, secondary-color-normal);
  $see-more-color-hover: map-get($theme, secondary-color-hover);

  h1:not([role]),
  *[role='h1'] {
    font-weight: $base-H1-font-weight;
  }

  h1:not([role]),
  *[role='h1'],
  h2:not([role]),
  *[role='h2'],
  h3:not([role]),
  *[role='h3'] {
    color: $base-primary-color;
  }

  h2:not([role]),
  *[role='h2'] {
    font-weight: $base-H2-font-weight;
  }

  h3:not([role]),
  *[role='h3'] {
    font-weight: $base-H3-font-weight;
  }

  a,
  .link {
    color: $base-hypertext-link-color-normal;

    &:hover {
      color: $base-hypertext-link-color-hover;
    }
  }

  .btn--see-more {
    @include link($see-more-color-normal, $see-more-color-hover, false);
  }

  $frame-hover-color: map-get($theme, link-with-image-hover-color);

  .link-with-image {
    &:hover,
    &.hover-link {
      .frame {
        background-color: $frame-hover-color;
      }
    }
  }

  .credit {
    font-weight: 500;
    font-size: 18px;
    color: #1f368b;

    @include desktop {
      font-size: 20px;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}

@include apply-themes($lcl-themes) {
  @include base-theme($theme-context-map);
}
