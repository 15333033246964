@import '~@angular/material/theming';

$app-typography: mat-typography-config(
  $font-family: 'Montserrat, sans-serif',
);

$app-primary: mat-palette($mat-deep-purple);
$app-accent: mat-palette($mat-amber, A200, A100, A400);
$app-theme: mat-light-theme($app-primary, $app-accent);

@include mat-core($app-typography);

@include mat-core-theme($app-theme);
@include mat-tabs-theme($app-theme);

mat-tab-group {
  .mat-tab-labels {
    .mat-tab-label {
      background-color: #ffffff !important;
      height: 62px;
      @include desktop {
        font-size: 16px;
      }
    }
  }

  .mat-tab-label-content {
    text-transform: uppercase;
  }

  .mat-tab-header {
    border-bottom: none;
    box-shadow: 0 4px 5px 0 rgba(114, 126, 253, 0.09);
    @include desktop {
      border-bottom: solid 2px #f1f1f6;
      box-shadow: none;
    }
  }

  .mat-tab-list {
    .mat-ink-bar {
      height: 5px;
    }
  }
}

.cdk-global-overlay-wrapper {
  align-items: center;
}
.cdk-overlay-dark-backdrop {
  background: rgba(25, 38, 114, 0.8);
}
