@import 'colors';

$mobile-break: 600px;
$break: 980px;
$xs-break: 390px;
$xl-break: 1290px;
$button-break: 500px;

//
// Typography variables
//
$font-size-xlarge: 28px;
$font-size-xlarge-desktop: 38px;
$font-size-large: 16px;
$font-size-large-desktop: 28px;
$font-size-body: 14px;
$font-size-body-desktop: 16px;

$font-weight-body-light: 300;
$font-weight-body: 400;
$font-weight-body-accent: 500;
$font-weight-body-accent-plus: 600;

$line-height-body: 22px;
$line-height-body-desktop: 28px;

//
// Slices variables
//
$slice-spacing: 50px;

//
// Breadcrumb variables
//
$breadcrumb-offset-top-desktop: 40px;
$breadcrumb-height-desktop: 18px;

//
// Heroes variables
//
$hero-spacing-bottom-mobile: 40px;
$hero-spacing-bottom-desktop: 50px;

$hero-margin-bottom-mobile: 40px;
$hero-margin-bottom-desktop: 50px;

$hero-subtitle-width-desktop: 700px;

//
// Forms variables
//
$form-field-input-padding-left: 20px;
$form-field-control-bottom-space: 28px;
$form-field-control-between-space: 40px;
$form-field-radio-between-space: 10px;

%form-field-rounded-border {
  border-radius: 5px;
  border: 1px solid $form-field-input-initial-color;
}

//
// Header
//
$header-height-regular-desktop: 139px;
$header-height-sticky-desktop: 70px;
