@import '~leaflet/dist/leaflet.css';

.agency-map-popup {
  font-family: Montserrat;
  font-size: 13px;
  line-height: 1.5em;
}

.agency-map-popup-name {
  font-weight: bold;
  color: #253e92;
}

.agency-map-popup-address,
.agency-map-popup-phone {
  margin: 5px 0;
  color: #5e5e5e;
}

.agency-map-popup-appointment {
  display: block;
  margin-top: 10px;
  text-align: center;
  text-decoration: underline;
  color: #727efd;
}

// Leaflet Map property divIcon requires a class as a string param not a list,
// so the two classes bellow are needed to handle the class switch for this property
.agency-map-marker-public-bank,
.agency-map-marker-private-bank,
.agency-map-marker-pro-bank {
  height: 40px;
  width: 30px;
  line-height: 32px;
  font-size: 12px;
  font-weight: 500;
  text-indent: 1px;
  text-align: center;
  color: #333;
  background-repeat: no-repeat;
}

.agency-map-marker-public-bank {
  background-image: url('/assets/images/agency-map-pin-public-bank.svg');
}

.agency-map-marker-private-bank {
  background-image: url('/assets/images/agency-map-pin-private-bank.svg');
}

.agency-map-marker-pro-bank {
  background-image: url('/assets/images/agency-map-pin-pro.svg');
}
