@import './core';

.link-with-image {
  .frame {
    display: inline-block;
    line-height: 0;
  }

  &:hover,
  &.hover-link {
    [role='img'],
    .picture {
      opacity: 0.8;
    }
  }
}
