$lighter-primary-color: #727efd;
$lighter-primary-color-hover: #a1b0ff;

//
// Body
//
$text-primary-color: #393939;
$text-hover-color: rgba(57, 57, 57, 0.8);

//
// Section
//
$section-title-color: #747474;
$section-title-line-color: #cfcfcf;

//
// Forms colors
//
$form-field-input-error-color: #ff4e3e;
$form-field-input-initial-color: #e7e7e7;
