@import '../../../../styles/colors';
@import '../../../../styles/variables';

@mixin form-field-input-core() {
  .form-field-input {
    width: 100%;
    box-sizing: border-box;
    padding: 18px 20px 19px $form-field-input-padding-left;
    background-clip: padding-box; // https://stackoverflow.com/questions/23211656/remove-ios-input-shadow/23211871

    @extend %form-field-rounded-border;

    &--multiline {
      resize: none;
      height: 250px;
    }

    &:focus {
      outline: none;
    }
  }
}

@mixin form-field-input-theme($theme) {
  $form-field-input-color: map-get($theme, tertiary-color-normal);
  $form-field-input-valid-field-color: map-get($theme, form-field-valid-field-color);
  $form-field-input-placeholder-color: map-get($theme, form-field-placeholder-color);

  .form-field-input {
    @include form-field-primary-text($form-field-input-color);

    &:focus {
      border: 1px solid $form-field-input-valid-field-color;
    }

    &::placeholder {
      color: $form-field-input-placeholder-color;
    }

    &:-ms-input-placeholder {
      color: $form-field-input-placeholder-color !important;
    }
  }
}
